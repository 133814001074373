import { Directive, ElementRef, inject, Input } from '@angular/core';

/**
 * Button UI
 * This directive is used to style a button element.
 *
 * @input color: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' - Optional. Defaults to 'primary'. Specifies the button color using PrimeNG's button styling classes.
 * @input shape: 'default' | 'text' | 'rounded' - Optional. Defaults to 'default'. Specifies the button style using PrimeNG's button styling classes.
 * @example
 *  Simple example:
 *   <button epg-button></button>
 * 
 * Options to use:
 *   <button epg-button [color]="'primary'" [shape]="'rounded'"></button>
 * 
 * 
 *   <button epg-button shape="text"><i class="pi pi-check"></i></button>
 *   
 */
@Directive({
    selector: '[epg-button]',
    standalone: true
  })
  export class UiEpgButtonDirective {

    el = inject(ElementRef);

    @Input() color: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' = 'primary';
    @Input() shape: 'default' | 'text' | 'rounded' = 'default';

    ngOnInit(): void {
      this.el.nativeElement.classList.add('p-element' , 'p-button', 'p-component', `p-button-${this.color}`, `p-button-${this.shape}`);
    }
}